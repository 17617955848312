<p-toast></p-toast>
<div class="p-grid p-fluid">
    <div class="p-col-12 p-md-4">
        <div class="card p-fluid min-height-card">
            <!--            <div class="p-grid p-fluid">-->
            <!--                <div class="p-col-3">-->
            <!--                    <h5>PO For</h5>-->
            <!--                </div>-->
            <!--                <div class="p-col-9">-->
            <!--                    <p-selectButton [options]="poForOptions" [(ngModel)]="selectedPOFor"-->
            <!--                                    (onChange)="changePOFor()" optionLabel="name"></p-selectButton>-->
            <!--                </div>-->
            <!--            </div>-->

            <div class="p-grid p-fluid">
                <div class="p-col">
                    <h5>PO For Vendor</h5>
                </div>
            </div>

            <form [formGroup]="poForm">
                <p-autoComplete
                    formControlName="selectedVendor"
                    ngDefaultControl
                    required
                    [suggestions]="filteredVendors"
                    (completeMethod)="filterVendor($event.query)"
                    (onSelect)="selectVendor($event.value)"
                    field="name"
                    [dropdown]="true"
                    [forceSelection]="true"
                    *ngIf="selectedPOFor.code === 'vendor'">
                </p-autoComplete>

                <!-- <div class="pt-2">
                    <p-checkbox
                        [formControl]="poForm.controls['allowMultipleInvoices']"
                        binary="true"
                        inputId="multiInvoices"
                        label="Leave Open After First Invoice?"></p-checkbox>
                </div> -->
            </form>

            <div *ngIf="hasExpiredInsurance" class="p-col-12 mx-5 mt-4">
                <p-message severity="error"
                    text="There are no approved certificates of insurance on file. Please make sure that one has been uploaded and approved by MPI management.">
                </p-message>
            </div>
        </div>
    </div>

    <div class="p-col-12 p-md-4">
        <div class="card p-fluid min-height-card">
            <h5>
                <span *ngIf="!showRecurringExpenses">Estimated</span
                ><span *ngIf="showRecurringExpenses">Calculated</span> Cost
            </h5>
            <form [formGroup]="poForm">
                <p-inputNumber
                    mode="decimal"
                    locale="en-US"
                    [minFractionDigits]="2"
                    [maxFractionDigits]="2"
                    [min]="0"
                    [max]="2147483647"
                    formControlName="estimatedCost" ngDefaultControl
                    name="estimatedCost"
                    [ngStyle]="{'pointer-events': showRecurringExpenses ? 'none' : ''}"
                    required></p-inputNumber>
            </form>
            <div class="mt-2 mb-0 p-field-checkbox">
                <p-checkbox
                    name="setupRecurringPayments" ngDefaultControl
                    [(ngModel)]="showRecurringExpenses"
                    (click)="changeSetupRecurringPayments()"
                    binary="true"
                    label="Setup Recurring Payments"></p-checkbox>
            </div>
        </div>
    </div>

    <!-- Locations -->
    <div class="p-col-12 p-md-4">
        <div class="card p-fluid min-height-card">
            <h5>Locations For Purchase Order Work</h5>
            <form [formGroup]="poForm">
                <p-multiSelect
                    [options]="locations"
                    formControlName="selectedLocations" ngDefaultControl
                    name="selectedLocations"
                    defaultLabel="Location(s)"
                    optionLabel="name"
                    class="multiselect-custom">
                    <ng-template let-value pTemplate="selectedItems">
                        <div
                            class="location-item location-item-value"
                            *ngFor="let option of poForm.controls['selectedLocations'].value">
                            <div>{{ option.name }} - {{ option.description }}</div>
                        </div>
                        <div
                            *ngIf="
                                !poForm.controls['selectedLocations'].value ||
                                poForm.controls['selectedLocations'].value.length == 0
                            ">
                            Select Locations
                        </div>
                    </ng-template>
                    <ng-template let-location pTemplate="item">
                        <div class="location-item">
                            <div>{{ location.name }} - {{ location.description }}</div>
                        </div>
                    </ng-template>
                </p-multiSelect>
            </form>
        </div>
    </div>

    <!-- Recurring Expenses Card -->
    <div *ngIf="showRecurringExpenses" class="p-col-12">
        <div class="card p-fluid">
            <h5>Setup Recurring Payments</h5>
            <div class="p-grid">
                <div class="p-col-12 p-md-4 p-lg-3">
                    <p-dropdown
                        [options]="recurringOptions"
                        name="recurringOptions" ngDefaultControl
                        [(ngModel)]="selectedOption"
                        [ngModelOptions]="{ standalone: true }"
                        (onChange)="changeRecurringOption($event)"
                        placeholder="Select an Option"></p-dropdown>
                </div>
                <div class="p-col-12 p-md-8 p-lg-9">
                    <form [formGroup]="recurringExpensesForm">
                        <div class="p-grid p-fluid">
                            <div class="p-col-4">
                                <span class="p-float-label">
                                    <p-inputNumber
                                        id="amount"
                                        formControlName="amount" ngDefaultControl
                                        mode="currency"
                                        currency="USD"
                                        locale="en-US"
                                        value="0"></p-inputNumber>
                                    <label for="amount">Amount</label>
                                </span>
                            </div>
                            <div *ngIf="selectedOption !== 'Bi-monthly'; else biMonthly" class="p-col-4">
                                <div *ngIf="selectedOption === 'Quarterly'; else notQuarterly">
                                    <span class="p-float-label">
                                        <p-calendar
                                            id="quarterly-date"
                                            formControlName="quarterlyDate"
                                            view="month"
                                            dateFormat="m/d/yy"
                                            [minDate]="minDate"
                                            [readonlyInput]="true"></p-calendar>
                                        <label for="quarterly-date">Day</label>
                                    </span>
                                </div>
                                <ng-template #notQuarterly>
                                    <span class="p-float-label">
                                        <p-calendar
                                            id="date"
                                            formControlName="startDate" ngDefaultControl
                                            dateFormat="m/d"
                                            [minDate]="minDate"></p-calendar>
                                        <label for="date">Day</label>
                                    </span>
                                </ng-template>
                            </div>
                            <ng-template #biMonthly>
                                <div class="p-col-4">
                                    <span class="p-float-label">
                                        <p-dropdown
                                            id="secondDate"
                                            inputId="secondDate"
                                            formControlName="bimonthlyDates"
                                            [options]="biMonthlyOptions"
                                            [autoDisplayFirst]="false"></p-dropdown>
                                        <label for="secondDate">Options</label>
                                    </span>
                                </div>
                            </ng-template>
                            <div class="p-col-4">

                                    <div *ngIf="!isNumOccurrences">
                                        <span class="p-float-label">
                                            <p-calendar
                                                id="endDate"
                                                formControlName="endDate" ngDefaultControl
                                                [minDate]="minDate"
                                                (onSelect)="changeDeadline(false)"></p-calendar>
                                            <label for="endDate">End Date</label>
                                        </span>
                                    </div>
                                    <div *ngIf="isNumOccurrences">
                                        <span class="p-float-label">
                                            <p-inputNumber
                                                id="num-occurrences"
                                                formControlName="numOccurrences"
                                                (onInput)="changeDeadline(true)"
                                                (onBlur)="changeDeadline(true)"
                                                (onFocus)="changeDeadline(true)"></p-inputNumber>
                                            <label for="num-occurrences"># of Occurrences</label>
                                        </span>
                                    </div>

                            </div>
                        </div>

                        <div class="p-grid p-fluid">
                            <div class="p-col-8">
                            </div>
                            <div class="p-col-4">
                                <button
                                    type="button"
                                    pButton
                                    pRipple
                                    (click)="
                                            isNumOccurrences = !isNumOccurrences; changeDeadline(isNumOccurrences)
                                        ">
                                    <span *ngIf="!isNumOccurrences">Change to # of Occurrences</span>
                                    <span *ngIf="isNumOccurrences">Change to End Date</span>
                                </button>
                            </div>
                        </div>

                        <p-message
                            *ngIf="completedRecurringForm()"
                            severity="info"
                            text="{{recurringDisplayMessage}}"></p-message>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <div class="p-col-12 p-md-12">
        <div class="card p-fluid">
            <h5>Reason for Work</h5>
            <form [formGroup]="poForm">
                <textarea
                    formControlName="reason"
                    rows="5"
                    cols="30"
                    placeholder="Your Message"
                    pInputTextarea></textarea>
            </form>
        </div>
    </div>

    <div class="p-col-12">
        <div class="card">
            <h5>Files</h5>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12">
                    <div>Please upload any required files to substantiate the request.</div>

                    <p-fileUpload
                        #fileInput
                        name="purchaseOrderUploads[]"
                        url="{{ uploadURL }}"
                        [showUploadButton]="false"
                        (onUpload)="onUpload($event)"
                        (onBeforeUpload)="modifyPOUpload($event)"
                        (onSelect)="fileSelect($event)"
                        (onRemove)="fileRemove($event)"
                        (onClear)="fileClear()"
                        (onError)="uploadError($event)"
                        multiple="multiple"
                        accept="{{ mpiApp.UPLOAD_ALLOWED_TYPES }}"
                        maxFileSize="{{ mpiApp.UPLOAD_MAX_BYTE_SIZE }}">
                        <ng-template pTemplate="content">
                            <ul *ngIf="uploadedFiles.length">
                                <li *ngFor="let file of uploadedFiles">{{ file.name }} - {{ file.size }} bytes</li>
                            </ul>
                        </ng-template>
                    </p-fileUpload>
                </div>
            </div>
        </div>
    </div>

    <div class="p-col-12">
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-11"></div>

            <div class="p-field p-col-1">
                <p-button
                    (click)="submit($event)"
                    label="Send Request"
                    class="p-mr-2 p-mb-2"
                    [disabled]="!poForm.valid || hasExpiredInsurance"
                    [loading]="this.isLoadingService.isLoading({ key: 'submit-po-request' })"></p-button>
            </div>
        </div>
    </div>
</div>

<ng-template #spinner>
    <p-progressSpinner></p-progressSpinner>
</ng-template>

<!--<pre>-->
<!--    {{locations | json}}-->
<!--</pre>-->
