import { alpha, numeric, prop, required, upperCase } from '@rxweb/reactive-form-validators';

export class FormRefundRequest {
    @required()
    @numeric()
    location: string;

    @prop()
    glCode: string;

    @required()
    date: string;

    // Internal sequential customer ID
    @prop()
    customerID: number;

    // User defined customer ID
    // Managers and Corporate do not see this
    @prop()
    custID: string;

    @required()
    customerName: string;

    @required()
    reason: string;

    @required()
    transactionAmount: number;

    @required()
    last4CardNo: number;

    @required()
    ticketNumber: string;

    @required()
    requestedAmount: number;

    @prop()
    customerSignature?: string;

    @required()
    @upperCase({message: 'Must be Uppercase'})
    payableTo: string;

    @required()
    @upperCase({ message: 'Must be Uppercase' })
    address: string;

    @prop()
    @upperCase({ message: 'Must be Uppercase' })
    address2?: string;

    @required()
    @upperCase({ message: 'Must be Uppercase' })
    city: string;

    @required()
    @upperCase({ message: 'Must be Uppercase' })
    state: string;

    @required()
    zipcode: number;
}
