import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';

import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { BadgeModule } from 'primeng/badge';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
import { CascadeSelectModule } from 'primeng/cascadeselect';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { ChipsModule } from 'primeng/chips';
//import { CodeHighlighterModule } from 'primeng/codehighlighter';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
//import { FullCalendarModule } from 'primeng/fullcalendar';
import { GalleriaModule } from 'primeng/galleria';
import { InplaceModule } from 'primeng/inplace';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { KnobModule } from 'primeng/knob';
//import { LightboxModule } from 'primeng/lightbox';
import { ListboxModule } from 'primeng/listbox';
import { MegaMenuModule } from 'primeng/megamenu';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { MultiSelectModule } from 'primeng/multiselect';
import { OrderListModule } from 'primeng/orderlist';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { PasswordModule } from 'primeng/password';
import { PickListModule } from 'primeng/picklist';
import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { RippleModule } from 'primeng/ripple';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ScrollTopModule } from 'primeng/scrolltop';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SidebarModule } from 'primeng/sidebar';
import { SkeletonModule } from 'primeng/skeleton';
import { SlideMenuModule } from 'primeng/slidemenu';
import { SliderModule } from 'primeng/slider';
import { SplitButtonModule } from 'primeng/splitbutton';
import { SplitterModule } from 'primeng/splitter';
import { StepsModule } from 'primeng/steps';
import { TabMenuModule } from 'primeng/tabmenu';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TagModule } from 'primeng/tag';
import { TerminalModule } from 'primeng/terminal';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { TimelineModule } from 'primeng/timeline';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { TreeTableModule } from 'primeng/treetable';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

import { AppCodeModule } from './app.code.component';
import { AppComponent } from './app.component';
import { AppMainComponent } from './app.main.component';
import { AppConfigComponent } from './app.config.component';
import { AppMenuComponent } from './app.menu.component';
import { AppMenuitemComponent } from './app.menuitem.component';
import { FormLayoutDemoComponent } from './demo/view/formlayoutdemo.component';
import { FloatLabelDemoComponent } from './demo/view/floatlabeldemo.component';
import { InvalidStateDemoComponent } from './demo/view/invalidstatedemo.component';
import { InputDemoComponent } from './demo/view/inputdemo.component';
import { ButtonDemoComponent } from './demo/view/buttondemo.component';
import { TableDemoComponent } from './demo/view/tabledemo.component';
import { ListDemoComponent } from './demo/view/listdemo.component';
import { TreeDemoComponent } from './demo/view/treedemo.component';
import { PanelsDemoComponent } from './demo/view/panelsdemo.component';
import { OverlaysDemoComponent } from './demo/view/overlaysdemo.component';
import { MediaDemoComponent } from './demo/view/mediademo.component';
import { MenusDemoComponent } from './demo/view/menusdemo.component';
import { MessagesDemoComponent } from './demo/view/messagesdemo.component';
import { MiscDemoComponent } from './demo/view/miscdemo.component';
import { EmptyDemoComponent } from './demo/view/emptydemo.component';
import { ChartsDemoComponent } from './demo/view/chartsdemo.component';
import { FileDemoComponent } from './demo/view/filedemo.component';
import { DocumentationComponent } from './demo/view/documentation.component';
import { DisplayComponent } from './utilities/display.component';
import { ElevationComponent } from './utilities/elevation.component';
import { FlexboxComponent } from './utilities/flexbox.component';
import { GridComponent } from './utilities/grid.component';
import { IconsComponent } from './utilities/icons.component';
import { WidgetsComponent } from './utilities/widgets.component';
import { SpacingComponent } from './utilities/spacing.component';
import { TypographyComponent } from './utilities/typography.component';
import { TextComponent } from './utilities/text.component';
import { AppCrudComponent } from './pages/app.crud.component';
import { AppInvoiceComponent } from './pages/app.invoice.component';
import { AppHelpComponent } from './pages/app.help.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppTimelineDemoComponent } from './pages/app.timelinedemo.component';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';
import { AppLoginComponent } from './pages/app.login.component';

import { CountryService } from './demo/service/countryservice';
import { CustomerService } from './demo/service/customerservice';
import { EventService } from './demo/service/eventservice';
import { IconService } from './demo/service/iconservice';
import { NodeService } from './demo/service/nodeservice';
import { PhotoService } from './demo/service/photoservice';
import { ProductService } from './demo/service/productservice';
import { MenuService } from './app.menu.service';
import { InviteVendorFormComponent } from './invite-vendor-form/invite-vendor-form.component';
import { VendorInformationComponent } from './vendor-information/vendor-information.component';
import { VendorPaymentsComponent } from './vendor-payments/vendor-payments.component';
import { VendorApprovalComponent } from './vendor-approval/vendor-approval.component';
import { LoginComponent } from './login/login.component';
import { PurchaseOrderRequestComponent } from './purchase-order-request/purchase-order-request.component';
import { PurchaseOrderApprovalComponent } from './purchase-order-approval/purchase-order-approval.component';
import { SubmitVendorInvoiceComponent } from './submit-vendor-invoice/submit-vendor-invoice.component';
import { ApproveVendorInvoiceComponent } from './approve-vendor-invoice/approve-vendor-invoice.component';
import { ExportApprovedInvoicesComponent } from './export-approved-invoices/export-approved-invoices.component';
import { PayApprovedInvoicesComponent } from './pay-approved-invoices/pay-approved-invoices.component';
import { ControlMessagesComponent } from './control-messages/control-messages.component';
import { InviteAcceptanceComponent } from './invite-acceptance/invite-acceptance.component';
import { ViewVendorsComponent } from './view-vendors/view-vendors.component';
import { ViewVendorInfoComponent } from './view-vendor-info/view-vendor-info.component';
import { AuthInterceptor } from './auth.interceptor';
import { UserManagementComponent } from './user-management/user-management.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { RedirectComponent } from './redirect/redirect.component';
import { FileVerificationControlComponent } from './file-verification-control/file-verification-control.component';
import { ViewInvoicesComponent } from './view-invoices/view-invoices.component';
import { ManagerInvoiceApprovalComponent } from './manager-invoice-approval/manager-invoice-approval.component';
import { CorporateInvoiceApprovalComponent } from './corporate-invoice-approval/corporate-invoice-approval.component';
import { ImportSagePaymentsComponent } from './import-sage-payments/import-sage-payments.component';
import { LogoutComponent } from './logout/logout.component';
import { SignaturePadComponent } from './signature-pad/signature-pad.component';
import { PurchaseOrderListComponent } from './purchase-order-list/purchase-order-list.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ControlErrorMessageDirective } from './control-error-message.directive';
import { DEVJABComponent } from './devjab/devjab.component';
import { VendorPendingSetupComponent } from './vendor-pending-setup/vendor-pending-setup.component';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { ChangePurchaseOrderComponent } from './change-purchase-order/change-purchase-order.component';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { ViewPaidInvoicesComponent } from './view-paid-invoices/view-paid-invoices.component';
import { VendorExpiringInsuranceComponent } from './vendor-expiring-insurance/vendor-expiring-insurance.component';
import { OpenManagerPosComponent } from './reports/open-manager-pos/open-manager-pos.component';
import { SettingsComponent } from './settings-page/settings.component';
import { BlankComponent } from './blank/blank.component';
import { ManagerDashboardComponent } from './manager-dashboard/manager-dashboard.component';
import { ExpiredDocumentsComponent } from './reports/expired-documents/expired-documents.component';
import { FilesWaitingApprovalComponent } from './files-waiting-approval/files-waiting-approval.component';
import { RefundRequestComponent } from './refund-request/refund-request.component';
import { ViewRefundRequestsComponent } from './view-refund-requests/view-refund-requests.component';

import { Router } from '@angular/router';
import { createErrorHandler, TraceService } from '@sentry/angular';
import { EmailLogsComponent } from './email-logs/email-logs.component';

@NgModule({
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        RxReactiveFormsModule,
        DynamicDialogModule,
        FormsModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        AccordionModule,
        AutoCompleteModule,
        AvatarModule,
        AvatarGroupModule,
        BadgeModule,
        BreadcrumbModule,
        ButtonModule,
        CalendarModule,
        CardModule,
        CarouselModule,
        CascadeSelectModule,
        ChartModule,
        CheckboxModule,
        ChipsModule,
        ChipModule,
        //CodeHighlighterModule,
        ConfirmDialogModule,
        ConfirmPopupModule,
        ColorPickerModule,
        ContextMenuModule,
        DataViewModule,
        DialogModule,
        DividerModule,
        DropdownModule,
        FieldsetModule,
        FileUploadModule,
        //FullCalendarModule,
        GalleriaModule,
        InplaceModule,
        InputGroupModule,
        InputGroupAddonModule,
        InputNumberModule,
        InputMaskModule,
        InputSwitchModule,
        InputTextModule,
        InputTextareaModule,
        KnobModule,
        //LightboxModule,
        ListboxModule,
        MegaMenuModule,
        MenuModule,
        MenubarModule,
        MessageModule,
        MessagesModule,
        MultiSelectModule,
        OrderListModule,
        OrganizationChartModule,
        OverlayPanelModule,
        PaginatorModule,
        PanelModule,
        PanelMenuModule,
        PasswordModule,
        ProgressSpinnerModule,
        ProgressBarModule,
        PickListModule,
        ProgressBarModule,
        RadioButtonModule,
        RatingModule,
        RippleModule,
        ScrollPanelModule,
        ScrollTopModule,
        SelectButtonModule,
        SidebarModule,
        SkeletonModule,
        SlideMenuModule,
        SliderModule,
        SplitButtonModule,
        SplitterModule,
        StepsModule,
        TagModule,
        TableModule,
        TabMenuModule,
        TabViewModule,
        TerminalModule,
        TieredMenuModule,
        TimelineModule,
        ToastModule,
        ToggleButtonModule,
        ToolbarModule,
        TooltipModule,
        TreeModule,
        TreeTableModule,
        VirtualScrollerModule,
        AppCodeModule,
    ],
    declarations: [
        AppComponent,
        AppMainComponent,
        AppConfigComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        EmailLogsComponent,
        FormLayoutDemoComponent,
        FloatLabelDemoComponent,
        InvalidStateDemoComponent,
        InputDemoComponent,
        ButtonDemoComponent,
        TableDemoComponent,
        ListDemoComponent,
        TreeDemoComponent,
        PanelsDemoComponent,
        OverlaysDemoComponent,
        MediaDemoComponent,
        MenusDemoComponent,
        MessagesDemoComponent,
        MessagesDemoComponent,
        MiscDemoComponent,
        ChartsDemoComponent,
        EmptyDemoComponent,
        FileDemoComponent,
        DocumentationComponent,
        DisplayComponent,
        ElevationComponent,
        FlexboxComponent,
        GridComponent,
        IconsComponent,
        WidgetsComponent,
        SpacingComponent,
        TypographyComponent,
        TextComponent,
        AppCrudComponent,
        AppLoginComponent,
        AppInvoiceComponent,
        AppHelpComponent,
        AppNotfoundComponent,
        AppErrorComponent,
        AppTimelineDemoComponent,
        AppAccessdeniedComponent,
        InviteVendorFormComponent,
        VendorInformationComponent,
        VendorPaymentsComponent,
        VendorApprovalComponent,
        VendorPendingSetupComponent,
        LoginComponent,
        PurchaseOrderRequestComponent,
        PurchaseOrderApprovalComponent,
        SubmitVendorInvoiceComponent,
        ApproveVendorInvoiceComponent,
        ExportApprovedInvoicesComponent,
        PayApprovedInvoicesComponent,
        ControlMessagesComponent,
        InviteAcceptanceComponent,
        ViewVendorsComponent,
        ViewVendorInfoComponent,
        UserManagementComponent,
        PasswordResetComponent,
        RedirectComponent,
        FileVerificationControlComponent,
        ViewInvoicesComponent,
        ManagerInvoiceApprovalComponent,
        CorporateInvoiceApprovalComponent,
        ImportSagePaymentsComponent,
        LogoutComponent,
        SignaturePadComponent,
        PurchaseOrderListComponent,
        ChangePasswordComponent,
        ControlErrorMessageDirective,
        DEVJABComponent,
        ChangePurchaseOrderComponent,
        ViewPaidInvoicesComponent,
        VendorExpiringInsuranceComponent,
        OpenManagerPosComponent,
        SettingsComponent,
        BlankComponent,
        ManagerDashboardComponent,
        ExpiredDocumentsComponent,
        FilesWaitingApprovalComponent,
        RefundRequestComponent,
        ViewRefundRequestsComponent,
    ],
    providers: [
        {
            provide: ErrorHandler,
            useValue: createErrorHandler({ showDialog: true }),
        },
        {
            provide: TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => { },
            deps: [TraceService],
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        CountryService,
        CustomerService,
        EventService,
        IconService,
        NodeService,
        PhotoService,
        ProductService,
        MenuService,
    ],
    bootstrap: [AppComponent]
})
// @ts-ignore
export class AppModule { }
