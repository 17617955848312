<p-toast></p-toast>
<p-messages [(value)]="error_msgs" [enableService]="false"></p-messages>
<div class="grid p-fluid">
    <div class="col-12 sm:col-6 md:col-4 xl:col-4 md-3">
        <div class="card p-fluid min-height-card">
            <div class="p-grid p-fluid">
                <div class="p-col-12 p-xl-4 flex justify-content-between">
                    <h5>Invoice For</h5>
                    <p-progressSpinner
                        *ngIf="this.isLoadingService.isLoading$({ key: 'getOrCreateReimbursementVendor' }) | async"
                        styleClass="w-2rem h-2rem" strokeWidth="3" fill="var(--surface-ground)"
                        animationDuration=".5s" />
                </div>
                <div *ngIf="hasExpiredInsurance" class="p-col-12">
                    <p-message severity="error"
                        text="There are no approved certificates of insurance on file. Please make sure that one has been uploaded and approved by MPI management.">
                    </p-message>
                </div>
                <div *ngIf="user_role === 'manager'" class="p-col-12 p-xl-8">
                    <p-selectButton [options]="forOptions" [(ngModel)]="selectedFor" name="selectedForButton"
                        ngDefaultControl (onChange)="changeFor()" optionLabel="name"
                        [disabled]="this.isLoadingService.isLoading$({ key: 'getOrCreateReimbursementVendor' }) | async">
                    </p-selectButton>
                </div>
            </div>

            <div class="p-col" *ngIf="selectedFor?.code === 'vendor'">
                <form [formGroup]="invoiceForm">
                    <p-autoComplete #POID inputId="selectPO" formControlName="selectedPO" ngDefaultControl required
                        [suggestions]="filteredPOs" (completeMethod)="filterPO($event.query)" field="poID"
                        [dropdown]="true" [forceSelection]="true" optionLabel="poID"
                        (onSelect)="setRemainingAmount($event.value); selectedOpenPO($event)" [scrollHeight]="'400px'"
                        [appendTo]="POID" panelStyleClass="large-panel">
                        <ng-template let-po pTemplate="item">
                            <div [ngClass]="{
                                    'striped-item': filteredPOs.indexOf(po) % 2 === 0
                                }">
                                <div class="grid p-2">
                                    <div class="col-12">
                                        <div class="flex justify-content-between">
                                            <div class="text-xl">{{ po.poID }} - {{ vendorMap[po.vendorID] }}</div>
                                            <div class="text-lg">{{ po.remainingBalance | currency: 'USD' }}</div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="grid">
                                            <div class="col-12 md:col-6 lg:col-4 text-truncate py-0"
                                                *ngFor="let location of po.locationsNameList">
                                                <strong>{{ location }}</strong> -
                                                {{ findLocation(location) }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </p-autoComplete>
                    <!-- <div class="p-col-12">
                        <p-checkbox
                            [formControl]="invoiceForm.controls['allowMultipleInvoices']"
                            [binary]="true"
                            label="Leave PO open until amount is used up?"></p-checkbox>
                    </div> -->
                </form>
            </div>
        </div>
    </div>

    <div class="col-12 sm:col-6 md:col-4 xl:col-4 md-2">
        <div class="card p-fluid min-height-card">
            <h5>Invoice Number</h5>
            <form [formGroup]="invoiceForm">
                <input type="text" pInputText formControlName="invoiceID" id="invoice-number" ngDefaultControl
                    [readonly]="this.vendorForSelectedPO?.type === 'reimbursement'" />
            </form>
            <small id="invoiceid-die-help" *ngIf="this.vendorForSelectedPO?.type !== 'reimbursement'">
                You must enter the ID from your invoice before submitting.
            </small>
            <small
                *ngIf="this.isLoadingService.isLoading$({ key: 'getOrCreateReimbursementVendor' }) | async">Calculating
                Invoice Number...</small>
            <small
                *ngIf="this.vendorForSelectedPO?.type === 'reimbursement' && !(this.isLoadingService.isLoading$({ key: 'getOrCreateReimbursementVendor' }) | async)">
                Calculated automatically. </small>
        </div>
    </div>

    <div class="col-12 sm:col-6 md:col-4 xl:col-4 md-2">
        <div class="card p-fluid min-height-card">
            <h5>Invoice Amount</h5>
            <form [formGroup]="invoiceForm">
                <p-inputNumber inputId="invoice-amount" mode="decimal" locale="en-US" [minFractionDigits]="2"
                    [maxFractionDigits]="2" [min]="0" [max]="2147483647" formControlName="invoiceAmount"
                    ngDefaultControl name="invoiceAmount" required (onInput)="changeAmount()"
                    (onBlur)="changedAmount()">
                </p-inputNumber>
            </form>
            <small id="amount-die-help">Amount due for payment on the invoice being submitted.</small>
        </div>
    </div>

    <div class="col-12 sm:col-6 md:col-4 xl:col-4 md-2">
        <div class="card p-fluid min-height-card">
            <h5>Invoice Date</h5>
            <input id="invoice-date" type="text" pInputText readonly [ngModel]="invoiceDate | date: 'longDate'"
                name="invoiceDateDisplay" (ngModelChange)="invoiceDate = $event" [disabled]="true" />
            <small id="invoice-date-help">The date of submission is automatically calculated.</small>
        </div>
    </div>

    <div class="col-12 sm:col-6 md:col-4 xl:col-4 md-2">
        <div class="card p-fluid min-height-card">
            <h5>Calculated Due Date</h5>
            <input id="due-date" type="text" pInputText readonly [ngModel]="dueDate | date: 'longDate'"
                name="dueDateDisplay" (ngModelChange)="dueDate = $event" [disabled]="true" />
            <small id="due-date-help">The due date is computed to be NET30 from date of submission.</small>
        </div>
    </div>

    <div class="col-12 sm:col-6 md:col-4 xl:col-4 md-2" *ngIf="selectedFor?.code === 'vendor'">
        <div class="card p-fluid min-height-card">
            <h5>Purchase Order Remaining Balance</h5>
            <input id="remaining-balance" type="text" pInputText readonly [ngModel]="remainingAmount | currency: 'USD'"
                name="remainingAmountDisplay" (ngModelChange)="remainingAmount = $event" [disabled]="true" />
            <small id="remaining-balance-help">The amount remaining for the selected purchase order</small>
        </div>
    </div>

    <div class="col-12" *ngIf="selectedFor?.code === 'reimbursement'">
        <div class="card p-fluid min-height-card">
            <div class="p-grid p-fluid">
                <div class="p-col-12 p-xl-5">
                    <h5>Location Cost Distribution</h5>
                </div>
                <div class="p-col-12 p-xl-7">
                    <form [formGroup]="glForm">
                        <div formArrayName="locations">
                            <p-table [value]="glformLocationArray.controls" responsiveLayout="scroll"
                                styleClass="p-datatable-sm p-datatable-gridlines">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="width: 3.5rem">
                                            <div class="flex align-items-center justify-content-start">
                                                <button pButton pRipple type="button" icon="pi pi-plus"
                                                    class="p-button-rounded p-button-success p-button-text"
                                                    (click)="addLocation()" *ngIf="true"></button>
                                            </div>
                                        </th>
                                        <th>Location Name</th>
                                        <!-- GL Code-->
                                        <th [ngClass]="{ 'hide-gl-codes': user_role !== 'accounting' }">
                                            <div class="flex justify-content-between">
                                                <div class="flex align-self-center justify-content-start">GL Code</div>
                                                <!--                                                <div-->
                                                <!--                                                    *ngIf="invoiceToShowVendor?.defaultGLCode === undefined"-->
                                                <!--                                                    class="flex align-self-center justify-content-end">-->
                                                <!--                                                    <p-tag severity="warning" value="No GL Code Set"></p-tag>-->
                                                <!--                                                </div>-->
                                                <div class="flex justify-content-end">
                                                    <button pButton pRipple
                                                        pTooltip="Click to set the default GL code for this vendor."
                                                        tooltipPosition="top" type="button" icon="pi pi-bookmark"
                                                        class="p-button-rounded p-button-text p-button-plain"
                                                        (click)="setDefaultGLCode()" *ngIf="true"></button>
                                                    <!-- <i
                                                        class="fa-solid fa-book-open"
                                                        (click)="setDefaultGLCode()"
                                                        pTooltip="Click to set the default GL code for this vendor."
                                                        tooltipPosition="top">
                                                    </i> -->
                                                </div>
                                            </div>
                                        </th>
                                        <th>Amount</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-i="rowIndex">
                                    <tr [formGroupName]="i">
                                        <td>
                                            <div class="flex align-self-center justify-content-start">
                                                <button pButton pRipple type="button" icon="pi pi-times"
                                                    class="p-button-rounded p-button-danger p-button-text p-button-sm"
                                                    (click)="deleteLocation(i)" *ngIf="true"></button>
                                            </div>
                                        </td>
                                        <td>
                                            <p-autoComplete inputId="auto-loc-{{i}}" formControlName="location"
                                                [suggestions]="locationSearchResults"
                                                (completeMethod)="searchLocations($event.query)"
                                                (onSelect)="selectLocation($event.value, i)"
                                                (onBlur)="selectLocation($event.target.value, i)" appendTo="body">
                                                <ng-template let-loc pTemplate="item">
                                                    {{ loc.name }} - {{ loc.description }}
                                                </ng-template>
                                            </p-autoComplete>
                                            <!-- <input
                                                    type="text"
                                                    pInputText
                                                    formControlName="location"
                                                    required
                                                    (onchange)="generateGLCode($event.target.value, i)"
                                                    (blur)="generateGLCode($event.target.value, i)" /> -->
                                            <app-control-messages
                                                [control]="glformLocationArray.controls[i].get('location')">
                                            </app-control-messages>
                                        </td>
                                        <td [ngClass]="{ 'hide-gl-codes': user_role !== 'accounting' }">
                                            <input type="text" pInputText formControlName="glCode" required />
                                            <app-control-messages
                                                [control]="glformLocationArray.controls[i].get('glCode')">
                                            </app-control-messages>
                                        </td>
                                        <td>
                                            <!--{{glformLocationArray.value[i].amount | currency: 'USD'}}-->
                                            <p-inputGroup>
                                                <p-inputGroupAddon>$</p-inputGroupAddon>
                                                <p-inputNumber mode="decimal" locale="en-US" [minFractionDigits]="2"
                                                    [maxFractionDigits]="2" [min]="0" formControlName="amount" required
                                                    [inputId]="amount">
                                                </p-inputNumber>
                                            </p-inputGroup>
                                            <app-control-messages
                                                [control]="glformLocationArray.controls[i].get('amount')">
                                            </app-control-messages>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                        <div class="m-2">
                            <!--                            <div>-->
                            <!--                                Total Amount Entered: {{ enteredAmountSum | currency: 'USD' }}-->
                            <!--                                <p-button-->
                            <!--                                    label="Save Locations"-->
                            <!--                                    [loading]="requestInProgress"-->
                            <!--                                    (onClick)="saveLocations()"-->
                            <!--                                    *ngIf="true"></p-button>-->
                            <!--                            </div>-->
                            <app-control-messages [control]="glformLocationArray"></app-control-messages>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12">
        <div class="card">
            <h5>Files</h5>
            <div class="p-fluid formgrid grid">
                <div class="field col-12">
                    <p-fileUpload #fileInput name="invoiceUploads[]" url="{{ uploadURL }}" [showUploadButton]="false"
                        (onUpload)="onUpload($event)" (onBeforeUpload)="modifyInvoiceUpload($event)"
                        (onSelect)="fileSelect($event)" (onRemove)="fileRemove($event)" (onClear)="fileClear()"
                        (onError)="uploadError($event)" multiple="multiple" accept="{{ mpiApp.UPLOAD_ALLOWED_TYPES }}"
                        maxFileSize="{{ mpiApp.UPLOAD_MAX_BYTE_SIZE }}">
                        <ng-template pTemplate="content">
                            <ul *ngIf="uploadedFiles.length">
                                <li *ngFor="let file of uploadedFiles">{{ file.name }} - {{ file.size }} bytes</li>
                            </ul>
                        </ng-template>
                    </p-fileUpload>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12">
        <div class="flex justify-content-end align-items-center">
            <div class="flex justify-content-end align-self-center mr-2">
                <p-message *ngIf="permanentError.error$ | async" severity="error" text="Error: Invoice upload failed">
                </p-message>
                <p-message *ngIf="hasExpiredInsurance" severity="error"
                    text="Error: No approved certificates of insurance on file">
                </p-message>
            </div>

            <div class="flex justify-content-end align-self-center">
                <p-button (onClick)="submit($event)" label="Send Request" class="mr-2 mb-2" [loading]="uploadInProgress"
                    [disabled]="!invoiceForm.valid || !glformLocationArray.valid || !fileInput.hasFiles() || uploadInProgress || hasExpiredInsurance">
                </p-button>
            </div>
        </div>
    </div>
</div>

<ng-template #spinner>
    <p-progressSpinner></p-progressSpinner>
</ng-template>

<!--<pre>-->
<!--    {{locations | json}}-->
<!--</pre>-->