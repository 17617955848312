<p-toast></p-toast>
<p-card header="Refund Request">
    <form [formGroup]="refundRequestForm" (ngSubmit)="submitForm()">
        <div class="formgrid grid p-fluid">
            <div class="field col-12 sm:col-12 md:col-4">
                <span class="p-float-label">
                    <p-autoComplete inputId="cust-name" formControlName="customerName" showEmptyMessage="true"
                        emptyMessage="No customers found." [inputStyle]="{'text-transform': 'uppercase'}"
                        [suggestions]="customerResults" (completeMethod)="searchCustomers($event.query)"
                        (onSelect)="selectCustomer($event.value)" (onBlur)="selectCustomer($event.target.value)">
                        <ng-template let-cust pTemplate="item">
                            {{ cust.name }} - {{ cust.location }}
                        </ng-template></p-autoComplete>
                    <!-- <input pInputText formControlName="customerName" id="cust-name" type="text" /> -->
                    <label for="cust-name">Customer Name</label>
                </span>
                <div>
                    <app-control-messages [control]="refundRequestForm.get('customerName')"></app-control-messages>
                </div>
            </div>
            <!-- Don't show the customer ID to Managers -->
            <!-- <div class="field col-12 sm:col-6 md:col-4">
                <span class="p-float-label">
                    <input pInputText formControlName="custID" id="cust-id" type="text" />
                    <label for="cust-id">Customer ID</label>
                </span>
                <div>
                    <app-control-messages [control]="refundRequestForm.get('custID')"></app-control-messages>
                </div>
            </div> -->
            <div class="field col-12 sm:col-6 md:col-4">
                <span class="p-float-label">
                    <p-autoComplete formControlName="location" inputId="location-field"
                        [suggestions]="locationSearchResults" (completeMethod)="searchLocations($event.query)"
                        (onSelect)="selectLocation($event.value)" (onBlur)="selectLocation($event.target.value)">
                        <ng-template let-loc pTemplate="item"> {{ loc.name }} - {{ loc.description }} </ng-template>
                    </p-autoComplete>
                    <label for="location-field">Location</label>
                </span>
                <div>
                    <app-control-messages [control]="refundRequestForm.get('location')"></app-control-messages>
                </div>
            </div>
            <div class="field col-12 sm:col-6 md:col-4">
                <span class="p-float-label">
                    <p-calendar formControlName="date" inputId="date"></p-calendar>
                    <label for="date">Date</label>
                </span>
                <div>
                    <app-control-messages [control]="refundRequestForm.get('date')"></app-control-messages>
                </div>
            </div>
            <div class="field col-12 sm:col-12 md:col-4">
                <span class="p-float-label">
                    <textarea pInputTextarea formControlName="reason" id="reason-for-refund" rows="5" cols="30"
                        autoResize="autoResize"></textarea>
                    <label for="reason-for-refund">Reason For Refund</label>
                </span>
                <div>
                    <app-control-messages [control]="refundRequestForm.get('reason')"></app-control-messages>
                </div>
            </div>
            <div class="field col-12 sm:col-6 md:col-4">
                <span class="p-float-label">
                    <p-inputNumber formControlName="transactionAmount" inputId="trans-amount" mode="currency"
                        currency="USD" locale="en-US">
                    </p-inputNumber>
                    <label for="trans-amount">Transaction Amount</label>
                </span>
                <div>
                    <app-control-messages [control]="refundRequestForm.get('transactionAmount')"></app-control-messages>
                </div>
            </div>
            <div class="field col-12 sm:col-6 md:col-4">
                <span class="p-float-label">
                    <p-inputNumber formControlName="last4CardNo" mode="decimal" inputId="last4" [useGrouping]="false">
                    </p-inputNumber>
                    <label for="last4">Last 4 Digits of Credit Card</label>
                </span>
                <div>
                    <app-control-messages [control]="refundRequestForm.get('last4CardNo')"></app-control-messages>
                </div>
            </div>
            <div class="field col-12 sm:col-6 md:col-4">
                <span class="p-float-label">
                    <input pInputText formControlName="ticketNumber" id="ticket-num" type="number" />
                    <label for="ticket-num">Ticket Number</label>
                </span>
                <div>
                    <app-control-messages [control]="refundRequestForm.get('ticketNumber')"></app-control-messages>
                </div>
            </div>
            <div class="field col-12 sm:col-6 md:col-4">
                <span class="p-float-label">
                    <p-inputNumber formControlName="requestedAmount" inputId="refund-amount" mode="currency"
                        currency="USD" locale="en-US">
                    </p-inputNumber>
                    <label for="refund-amount">Refund Amount</label>
                </span>
                <div>
                    <app-control-messages [control]="refundRequestForm.get('requestedAmount')"></app-control-messages>
                </div>
            </div>
            <div class="field col-12 sm:col-6 md:col-4">
                <span class="p-float-label">
                    <input pInputText formControlName="payableTo" id="payable-to" type="text"
                        oninput="let payableInput=this.selectionStart;this.value=this.value.toUpperCase();this.setSelectionRange(payableInput, payableInput);" />
                    <label for="payable-to">Make Check Payable To</label>
                </span>
                <div>
                    <app-control-messages [control]="refundRequestForm.get('payableTo')"></app-control-messages>
                </div>
            </div>
            <div class="field col-12 sm:col-12 md:col-4">
                <span class="p-float-label">
                    <input pInputText formControlName="address" id="address" type="text"
                        oninput="let addressInput=this.selectionStart;this.value=this.value.toUpperCase();this.setSelectionRange(addressInput, addressInput);" />
                    <label for="address">Address</label>
                </span>
                <input pInputText formControlName="address2" placeholder="Suite/Apt #" id="address2" type="text"
                    value=""
                    oninput="let address2Input=this.selectionStart;this.value=this.value.toUpperCase();this.setSelectionRange(address2Input, address2Input);" />
                <div>
                    <app-control-messages [control]="refundRequestForm.get('address')"></app-control-messages>
                </div>
            </div>
            <div class="field col-12 sm:col-12 md:col-4">
                <span class="p-float-label">
                    <input pInputText formControlName="city" id="city" type="text"
                        oninput="let cityInput=this.selectionStart;this.value=this.value.toUpperCase();this.setSelectionRange(cityInput, cityInput);" />
                    <label for="city">City</label>
                </span>
                <div>
                    <app-control-messages [control]="refundRequestForm.get('city')"></app-control-messages>
                </div>
            </div>
            <div class="field col-12 sm:col-12 md:col-4">
                <span class="p-float-label">
                    <input pInputText formControlName="state" id="state" type="text"
                        oninput="let stateInput=this.selectionStart;this.value=this.value.toUpperCase();this.setSelectionRange(stateInput, stateInput);" />
                    <label for="state">State</label>
                </span>
                <div>
                    <app-control-messages [control]="refundRequestForm.get('state')"></app-control-messages>
                </div>
            </div>
            <div class="field col-12 sm:col-12 md:col-4">
                <span class="p-float-label">
                    <p-inputNumber formControlName="zipcode" mode="decimal" inputId="zipcode" [useGrouping]="false">
                    </p-inputNumber>
                    <label for="zipcode">Zipcode</label>
                </span>
                <div>
                    <app-control-messages [control]="refundRequestForm.get('zipcode')"></app-control-messages>
                </div>
            </div>
            <div class="col-12">
                <div class="field col-12 sm:col-6 md:col-4 flex align-self-center">
                    <!-- <span class="p-float-label">
                    <input pInputText formControlName="customerSignature" id="cust-signature" type="text" />
                    <label for="cust-signature">Customer Signature</label>
                </span> -->
                    <div class="grid">
                        <h5 class="col-12">Signature</h5>
                        <app-signature-pad class="col-12"></app-signature-pad>
                        <div class="col-12 flex justify-content-between">
                            <div>
                                <button pButton pRipple type="button" label="Save Signature" icon="pi pi-check"
                                    class="p-button-success" (click)="saveSignature()"
                                    [disabled]="signatureForm.valid"></button>
                            </div>

                            <div>
                                <button pButton pRipple type="button" label="Clear"
                                    class="p-button-danger p-button-text" (click)="clearCanvas()"></button>
                            </div>
                        </div>
                        <div>
                            <app-control-messages
                                [control]="refundRequestForm.get('customerSignature')"></app-control-messages>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <h5>Files</h5>
                <div class="p-fluid formgrid grid">
                    <div class="field col-12">
                        <p-fileUpload #fileInput name="refundUploads[]" url="{{ uploadURL }}" [showUploadButton]="false"
                            (onUpload)="onUpload($event)" (onBeforeUpload)="modifyRefundUpload($event)"
                            (onSelect)="fileSelect($event)" (onRemove)="fileRemove($event)" (onClear)="fileClear()"
                            (onError)="uploadError($event)" multiple="multiple"
                            accept="{{ mpiApp.UPLOAD_ALLOWED_TYPES }}" maxFileSize="{{ mpiApp.UPLOAD_MAX_BYTE_SIZE }}">
                            <ng-template pTemplate="content">
                                <ul *ngIf="uploadedFiles.length">
                                    <li *ngFor="let file of uploadedFiles">{{ file.name }} - {{ file.size }} bytes</li>
                                </ul>
                            </ng-template>
                        </p-fileUpload>
                    </div>
                </div>
            </div>
        </div>
        <p-message *ngIf="permanentError.error$ | async" severity="error" text="Error: Refund Request upload failed">
        </p-message>
        <button pButton type="submit">Submit Request</button>
        <button pButton type="button" class="p-button-warning ml-2" (click)="clearForm()">Clear Form</button>
    </form>
</p-card>